const hostname = "cnuip.com";
const domain = process.env.NODE_ENV === `development1` ? "localhost" : `.${hostname}`;
const projectHosts = {
  web: 'web',  // pc主站
  admin: 'adminzg',  // 交易系统
  trade: 'adminzg',
  data: 'datazg',  // dataServer
  cms: 'cmszg',  // 楼层
  shenbao: 'shenbaozg', // 申报
  qxt: 'qxtzg',  // 企校通
  service: 'servicezg', // 科技服务
  seller: 'sellerzg' // 卖家中心
}
export {
  hostname,
  domain,
  projectHosts
};